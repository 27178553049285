import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import SEO from '../components/seo'
import Layout from '../components/layout'
import { Article } from '../components/article'

class BlogPostTemplate extends React.Component {
  render() {
    const {
      location,
      data: {
        site: { siteMetadata },
        markdownRemark: post,
      },
    } = this.props

    const {
      frontmatter: { title, date },
      fields: { postPath },
    } = post

    return (
      <Layout location={location}>
        <Helmet title={`${title} | ${siteMetadata.title}`} />
        <SEO post={post} />
        <Article
          title={title}
          date={date}
          contentHTML={post.html}
          disqusPath={postPath}
        />
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { postPath: { eq: $path } }) {
      id
      html
      timeToRead
      excerpt
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
      fields {
        postPath
      }
    }
  }
`
